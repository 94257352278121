const SUBTYPES_TO_CLASS_NAMES = {
    opinion: 'opinion',
    podcast: 'podcast',
};

const SUBTYPES_WITH_ICON = new Set(['opinion', 'podcast']);

export const hasSubtypeWithIcon = (subtype: string) => {
    return SUBTYPES_WITH_ICON.has(subtype);
};

export const mapSubtypeToClassName = (subtype: string, classes: CSSModuleClasses) => {
    const className = SUBTYPES_TO_CLASS_NAMES[subtype];
    return className ? classes[className] : null;
};
