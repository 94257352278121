import { clsx } from 'clsx';
import type { TeaserAuthorType } from '../../../../../core/front/types';
import type { TeaserSizeType } from '../../types';
import css from './TeaserBylineImage.module.css';

type TeaserBylineProps = {
    author: TeaserAuthorType;
    teaserSize: TeaserSizeType;
};

const TeaserBylineImage = ({ author, teaserSize }: TeaserBylineProps) => {
    const { image: authorImage, title: authorName } = author || {};
    if (!authorImage) return;

    return (
        <figure
            className={clsx(
                teaserSize === 'stripe'
                    ? css.stripeBylineImageContainer
                    : css.bylineImageContainer,
                css[teaserSize]
            )}
        >
            <img
                className={css.bylineImage}
                src={authorImage.url}
                alt={`Bilde av ${authorName}`}
                loading="lazy"
            />
        </figure>
    );
};

export default TeaserBylineImage;
