import type { TeaserVariantTitleSize } from '@innhold/core/front/types';
import { getTeaserTitleFontStyles } from '@innhold/lib/styles/titles';
import { formatTitle } from '../../../utils/titles';
import css from '../TeaserTitle.module.css';

export type DefaultTitleProps = {
    sourceId: string;
    text: string[];
    fontSize: TeaserVariantTitleSize;
    fontWeight: number;
};

const Prefix = ({ prefix }: { prefix?: string }) => {
    return prefix ? <span className={css.prefix}>{prefix}</span> : null;
};

const Suffix = ({ suffix }: { suffix?: string }) => {
    return suffix ? <span className={css.suffix}>{suffix}</span> : null;
};

export const DefaultTitle = ({
    sourceId,
    text: sourceText,
    fontSize,
    fontWeight,
}: DefaultTitleProps) => {
    const titleId = `title-${sourceId}`;
    const { prefix, text, suffix } = formatTitle(sourceText);

    return (
        <h3 id={titleId} className={css.mainTitle}>
            {text.map((line: string, lineIndex: number) => {
                const isFirstLine = lineIndex === 0;
                const isLastLine = lineIndex === text.length - 1;

                return (
                    <span key={`title-row-${lineIndex}`} className={css.line}>
                        {isFirstLine ? <Prefix prefix={prefix} /> : null}
                        {line}
                        {isLastLine ? <Suffix suffix={suffix} /> : null}
                    </span>
                );
            })}
            <style>
                {getTeaserTitleFontStyles(`#${titleId} span`, fontSize, fontWeight)}
            </style>
        </h3>
    );
};
