import { clsx } from 'clsx';
import { getTeaserTitleFontStyles } from '@innhold/lib/styles/titles';
import type { DefaultTitleProps } from './Default';
import css from '../TeaserTitle.module.css';

export const RawTitle = ({
    sourceId,
    text: sourceText,
    fontSize,
    fontWeight,
}: DefaultTitleProps) => {
    const titleId = `title-${sourceId}`;
    const rawTitle = sourceText.join(' ');

    return (
        <h3 id={titleId} className={clsx(css.mainTitle, css.raw)}>
            <span>{rawTitle}</span>
            <style>
                {getTeaserTitleFontStyles(`#${titleId} span`, fontSize, fontWeight)}
            </style>
        </h3>
    );
};
