import { useEffect, useState } from 'react';
import { useStore } from '@nanostores/react';
import { Teaser } from '@innhold/components/front/Teaser/Teaser';
import {
    $isReady,
    getTeaserBatch,
    getTeaserBundleBatch,
} from '@innhold/core/front/stores/blend-store';
import type { TeaserResponseType } from '@innhold/core/front/types';
import type { BlockLayoutType, LayoutTemplate } from '../../types';
import { flipLayoutItems, getLayoutSize, isFlipLayout } from '../../utils/layouts';
import { getTeaserSize } from '../../utils/teasers';

type BlockProps = {
    bundle?: { offset: number };
    layout: BlockLayoutType;
    offset: number;
    template: LayoutTemplate;
};

export const Block = ({ bundle, layout, offset, template }: BlockProps) => {
    const isBlendReady = useStore($isReady);

    const [teasers, setTeasers] = useState<TeaserResponseType[]>(
        Array.from({ length: getLayoutSize(layout) })
    );

    useEffect(() => {
        if (!isBlendReady) {
            return;
        }

        const fetchTeasersBatch = async () => {
            const batch = bundle
                ? await getTeaserBundleBatch(bundle, offset, getLayoutSize(layout))
                : await getTeaserBatch(offset, getLayoutSize(layout));
            const batchTeasers = isFlipLayout(layout)
                ? flipLayoutItems(batch, layout)
                : batch;

            setTeasers(
                batchTeasers.map((teaser, indexInBatch) => ({
                    ...teaser,
                    meta: {
                        ...teaser.meta,
                        position: template[indexInBatch],
                    },
                }))
            );
        };

        fetchTeasersBatch();
    }, [isBlendReady, bundle, layout, offset, template]);

    if (!teasers.length) {
        return <div />;
    }

    return teasers.map((teaser, indexInBatch) => (
        <Teaser
            key={`teaser-${indexInBatch}`}
            data={teaser}
            size={getTeaserSize(layout, indexInBatch)}
        />
    ));
};
