import type { TeaserImportance } from '@innhold/core/front/types';

const FONT_WEIGHTS_BY_IMPORTANCE: Record<TeaserImportance, number> = {
    notice: 500,
    standard: 600,
    high: 700,
    breaking: 900,
    'ultra-breaking': 900,
};

export const getFontWeight = (importance: TeaserImportance) => {
    return FONT_WEIGHTS_BY_IMPORTANCE[importance];
};
