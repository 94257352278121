import type { TeaserResponseType } from '@innhold/core/front/types';

type TeaserSkins = TeaserResponseType['skins'];

const SKINS_TO_CLASS_NAMES = {
    aksjelive: 'aksjelive',
    borskommentar: 'borskommentar',
    kommentar: 'kommentar',
    kronikk: 'kronikk',
    'e24-pluss': 'pluss',
    'e24-spaltist': 'spaltist',
};

const SKINS_WITH_BYLINE = new Set(['borskommentar', 'kommentar']);

const hasSkin = (skins: TeaserSkins, skinToLookFor: string) => {
    return skins.includes(skinToLookFor) ?? false;
};

export const hasSkinWithByline = (skins: TeaserSkins = []) => {
    return skins.some((skin) => SKINS_WITH_BYLINE.has(skin));
};

export const isPluss = (skins: TeaserSkins = []) => {
    return hasSkin(skins, 'e24-pluss');
};

export const mapSkinsToClassNames = (
    skins: TeaserSkins = [],
    classes: CSSModuleClasses
) => {
    return skins
        .map((skin) => {
            const className = SKINS_TO_CLASS_NAMES[skin];
            return className ? classes[className] : null;
        })
        .filter(Boolean);
};
