import type { TeaserSizeType } from '../../Teaser/types';
import type { BlockLayoutType } from '../types';

export const getTeaserSize = (
    layout: BlockLayoutType,
    indexInBlock: number
): TeaserSizeType => {
    if (layout === 'fullWidth') {
        return 'full';
    }
    if (layout === 'breaking-tower') {
        return indexInBlock === 0 ? 'threeQuarters' : 'oneQuarter';
    }

    if (layout === 'tower') {
        return indexInBlock === 0 ? 'twoThirds' : 'oneThird';
    }

    if (layout === 'flipped-tower') {
        return indexInBlock === 2 ? 'twoThirds' : 'oneThird';
    }

    if (layout === 'double') {
        return 'half';
    }

    if (layout === 'triple') {
        return 'oneThird';
    }

    if (layout === 'stripe') {
        return 'stripe';
    }

    return 'full';
};
