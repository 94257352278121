import type { TeaserElementSizeType, TeaserSizeType } from '../types';

export const mapSizeToElementSize = (
    size: TeaserSizeType
): TeaserElementSizeType | '' => {
    switch (size) {
        case 'full':
        case 'threeQuarters':
        case 'twoThirds':
            return 'large';
        case 'half':
            return 'medium';
        case 'oneThird':
        case 'oneQuarter':
            return 'small';
        case 'stripe':
            return 'stripe';
        default:
            return '';
    }
};
