import { clsx } from 'clsx';
import { getIcon, getIconHtml } from '../../icons';
import type { TeaserSizeType } from '../../types';
import { mapSubtypeToClassName } from '../../utils/subtypes';
import css from './TeaserIcon.module.css';

export const TeaserIcon = ({
    subtype,
    teaserSize,
}: {
    subtype: string;
    teaserSize: TeaserSizeType;
}) => {
    const icon = getIcon(subtype);
    if (!icon) return null;

    return (
        <div
            className={clsx(
                teaserSize === 'stripe' ? css.stripeIconContainer : css.iconContainer,
                css[teaserSize],
                mapSubtypeToClassName(subtype, css)
            )}
        >
            <span
                className={css.icon}
                dangerouslySetInnerHTML={{ __html: getIconHtml(icon) }}
            />
        </div>
    );
};
