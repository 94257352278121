import { clsx } from 'clsx';
import { getImageData } from '@innhold/components/front/Teaser/utils/images.ts';
import type { TeaserImageType, TeaserResponseType } from '@innhold/core/front/types';
import { getStaticAssetUrl } from '@innhold/lib/assets/getStaticAssetUrl';
import type { TeaserSizeType } from '../../types';
import { isPluss } from '../../utils/skins';
import css from './TeaserImage.module.css';

type TeaserImageProps = {
    image: TeaserImageType;
    skins?: TeaserResponseType['skins'];
    teaserSize?: TeaserSizeType;
};

export const TeaserImage = ({ image, skins = [], teaserSize }: TeaserImageProps) => {
    const { hotspot = null, sizes } = image;
    const { url: imageUrl, srcSet, sizesString } = getImageData(sizes, teaserSize);

    return (
        <figure className={clsx(css.imageContainer, css[teaserSize])}>
            <img
                className={css.image}
                src={imageUrl}
                srcSet={srcSet}
                sizes={sizesString}
                alt=""
                style={{
                    objectPosition: `${hotspot.pctX * 100}% ${hotspot.pctY * 100}%`,
                }}
            />
            {isPluss(skins) && (
                <img
                    className={css.plusIcon}
                    src={getStaticAssetUrl('/cta-gfx/plus.svg')}
                    alt=""
                />
            )}
        </figure>
    );
};
