import type { TeaserSizeType } from '@innhold/components/front/Teaser/types.ts';
import type { TeaserImageSize } from '@innhold/core/front/types.ts';

const DEFAULT_IMAGE_SIZE = 980;

type ImageSizeDesktop = typeof DEFAULT_IMAGE_SIZE | 704 | 648 | 482 | 316 | 260;
type ImageSizeMobile = '100vw' | '50vw' | '33vw';

export const getDefaultImageUrl = (sizes: TeaserImageSize[]) => {
    return sizes.find((size) => size.width === DEFAULT_IMAGE_SIZE)?.url;
};

const getImageSrcSet = (sizes: TeaserImageSize[]) => {
    return sizes.map((size) => `${size.url} ${size.width}w`).join(', ');
};

const getImageSizesString = (teaserSize: TeaserSizeType) => {
    let desktopSize: `${ImageSizeDesktop}px` = '980px';
    let sizeForMobile: ImageSizeMobile = '100vw';

    switch (teaserSize) {
        case 'full':
            desktopSize = '980px';
            sizeForMobile = '100vw';
            break;
        case 'threeQuarters':
            desktopSize = '704px';
            sizeForMobile = '100vw';
            break;
        case 'twoThirds':
            desktopSize = '648px';
            sizeForMobile = '100vw';
            break;
        case 'half':
            desktopSize = '482px';
            sizeForMobile = '50vw';
            break;
        case 'oneThird':
            desktopSize = '316px';
            sizeForMobile = '50vw';
            break;
        case 'oneQuarter':
            desktopSize = '260px';
            sizeForMobile = '50vw';
            break;
        case 'stripe':
            desktopSize = '316px';
            sizeForMobile = '33vw';
            break;
    }

    return `(max-width: 820px) ${sizeForMobile}, ${desktopSize}`;
};

export const getImageData = (sizes: TeaserImageSize[], teaserSize: TeaserSizeType) => {
    const imgUrl = getDefaultImageUrl(sizes);
    const srcSet = getImageSrcSet(sizes);
    const sizesString = getImageSizesString(teaserSize);

    return { url: imgUrl, srcSet, sizesString };
};
