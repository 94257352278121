import type { TeaserResponseType } from '@innhold/core/front/types';

type TeaserImportance = TeaserResponseType['importance'];

const IMPORTANCE_TO_CLASS_NAMES = {
    breaking: 'breaking',
    'ultra-breaking': 'ultraBreaking',
};

export const mapImportanceToClassName = (
    importance: TeaserImportance,
    classes: CSSModuleClasses
) => {
    const className = IMPORTANCE_TO_CLASS_NAMES[importance];
    return className ? classes[className] : null;
};
