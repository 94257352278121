import { clsx } from 'clsx';
import { getIcon, getIconHtml } from '../../icons';
import css from './TeaserSectionIcon.module.css';

export type TeaserSectionIconProps = {
    subtype: string;
    role: 'primary' | 'supplementary';
};

const TeaserSectionIcon = ({ subtype, role }: TeaserSectionIconProps) => {
    const icon = getIcon(subtype);
    if (!icon) return null;

    return (
        <div className={clsx(css.iconContainer, css[role])}>
            <span
                className={css.icon}
                dangerouslySetInnerHTML={{ __html: getIconHtml(icon) }}
            />
        </div>
    );
};

export default TeaserSectionIcon;
