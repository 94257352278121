import opinionSvg from './opinion.svg?raw';
import podcastSvg from './podcast.svg?raw';

const TEASER_ICONS = { opinion: opinionSvg, podcast: podcastSvg } as const;

const SUBTYPE_ICONS = {
    opinion: 'opinion',
    podcast: 'podcast',
} as { [key: string]: keyof typeof TEASER_ICONS };

export const getIcon = (subtype: string) => SUBTYPE_ICONS[subtype];
export const getIconHtml = (icon: string) => TEASER_ICONS[icon];
