import { clsx } from 'clsx';
import type { TeaserResponseType } from '@innhold/core/front/types';
import { TeaserByline } from './components/TeaserByline';
import TeaserBylineImage from './components/TeaserBylineImage';
import { TeaserImage } from './components/TeaserImage';
import { TeaserTitle } from './components/TeaserTitle';
import type { TeaserSizeType } from './types';
import { getTeaserDataAttributes } from './utils/html';
import { mapImportanceToClassName } from './utils/importance';
import { hasSkinWithByline, mapSkinsToClassNames } from './utils/skins';
import { mapSubtypeToClassName } from './utils/subtypes';
import css from './Teaser.module.css';

type TeaserProps = {
    data: TeaserResponseType;
    size: TeaserSizeType;
};

export const Teaser = ({ data: teaser, size }: TeaserProps) => {
    if (!teaser) {
        return <div className={clsx(css.teaser, css[size])} />;
    }

    const {
        sourceId,
        authors,
        importance = 'standard',
        subtype,
        skins,
        url,
        image,
        kicker,
        title,
        subtitle: section,
    } = teaser;

    const withImage = Boolean(image);
    const withByline = hasSkinWithByline(skins);

    const teaserDataAttributes = getTeaserDataAttributes(teaser, size);

    return (
        <a
            href={url}
            className={clsx(
                css.teaser,
                css[size],
                mapImportanceToClassName(importance, css),
                mapSubtypeToClassName(subtype, css),
                mapSkinsToClassNames(skins, css),
                {
                    [css.withoutImage]: !withImage,
                }
            )}
            {...teaserDataAttributes}
        >
            {withImage && (
                <div className={css.teaserImage}>
                    <TeaserImage image={image} skins={skins} teaserSize={size} />
                    {withByline && (
                        <TeaserBylineImage author={authors?.[0]} teaserSize={size} />
                    )}
                </div>
            )}
            <div className={css.teaserDetails}>
                {withByline && <TeaserByline author={authors?.[0]} teaserSize={size} />}
                <TeaserTitle
                    sourceId={sourceId}
                    kicker={kicker}
                    value={title}
                    section={section}
                    importance={importance}
                    skins={skins}
                    subtype={subtype}
                    teaserSize={size}
                />
            </div>
        </a>
    );
};
