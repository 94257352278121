import type { TeaserVariantTitleSize } from '@innhold/core/front/types.ts';
import { injectSafariOnlyStyles } from '@innhold/lib/styles/safari.ts';

export const getTeaserTitleFontStyles = (
    selector: string,
    fontSize: TeaserVariantTitleSize,
    fontWeight: number
) => {
    // clamp (val, val, val) is a one of way to make Safari apply the font-size property when zoom in
    // working on Safari 18.x
    return `
        ${selector} {
            font-weight: ${fontWeight};
            font-size: ${fontSize.px}px;
            font-size: ${fontSize.cqw}cqw;
        }
        ${injectSafariOnlyStyles(selector, 'font-size', `clamp(${fontSize.cqw}cqw, ${fontSize.cqw}cqw, ${fontSize.cqw}cqw)`)}`;
};
