import { clsx } from 'clsx';
import type { TeaserVariantSubtitle } from '../../../../../core/front/types';
import type { TeaserSizeType } from '../../types';
import TeaserSectionIcon, { type TeaserSectionIconProps } from '../TeaserSectionIcon';
import css from './TeaserSection.module.css';

type TeaserSectionProps = {
    section: TeaserVariantSubtitle & { icon?: TeaserSectionIconProps };
    teaserSize: TeaserSizeType;
};

export const TeaserSection = ({ section, teaserSize }: TeaserSectionProps) => {
    const { icon, label, value } = section;

    return (
        <div className={clsx(css.section, css[teaserSize])}>
            {icon && <TeaserSectionIcon {...icon} />}
            <span className={css.label}>{label}</span>
            {value ? (
                <>
                    <span className={css.separator}>|</span>
                    <span>{value}</span>
                </>
            ) : null}
        </div>
    );
};
