import type { TeaserResponseType } from '@innhold/core/front/types';
import { DefaultTitle } from './Default';
import type { DefaultTitleProps } from './Default';
import { RawTitle } from './Raw';

type TitleProps = DefaultTitleProps & {
    importance: TeaserResponseType['importance'];
};

const getTitleComponent = (importance: TeaserResponseType['importance']) => {
    if (importance === 'ultra-breaking') return RawTitle;
    return DefaultTitle;
};

export const Title = ({ importance, ...otherTitleProps }: TitleProps) => {
    const TitleComponent = getTitleComponent(importance);
    return <TitleComponent {...otherTitleProps} />;
};
