type Title = {
    prefix?: string;
    text: string[];
    suffix?: string;
};

const extractPrefix = (title: Pick<Title, 'text'>) => {
    const { text } = title;
    const firstRow = text[0];

    const prefixPosition = firstRow.indexOf('|');
    const withPrefix = prefixPosition > 0 && prefixPosition < firstRow.length - 1;
    if (withPrefix) {
        return {
            ...title,
            prefix: `${firstRow.slice(0, prefixPosition).trim()}|`,
            text: text
                .map((row, rowIndex) =>
                    // Remove prefix from first title row
                    rowIndex === 0 ? row.slice(prefixPosition + 1).trim() : row
                )
                .filter((part) => part.length > 0),
        };
    }

    return title;
};

const extractSuffix = (title: Pick<Title, 'text'>) => {
    const { text } = title;
    const lastRow = text[text.length - 1];

    const withArrowSuffix = lastRow.endsWith('→');
    if (withArrowSuffix) {
        return {
            ...title,
            text: text
                .map((row, rowIndex) =>
                    // Remove suffix from last title row
                    rowIndex === text.length - 1
                        ? row.slice(0, row.length - 1).trim()
                        : row
                )
                .filter((part) => part.length > 0),
            suffix: ' →',
        };
    }

    return title;
};

export const formatTitle = (text: string[]) => {
    return extractPrefix(extractSuffix({ text })) as Title;
};
