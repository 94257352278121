import { clsx } from 'clsx';
import type { TeaserAuthorType } from '@innhold/core/front/types';
import type { TeaserSizeType } from '../../types';
import css from './TeaserByline.module.css';

type TeaserBylineProps = {
    author: TeaserAuthorType;
    teaserSize: TeaserSizeType;
};

export const TeaserByline = ({ author, teaserSize }: TeaserBylineProps) => {
    if (!author) {
        return null;
    }

    const { title: name } = author;

    return (
        <div className={clsx(css.byline, css[teaserSize])}>
            <p className={css.authorDetails}>
                <span className={css.prefix}>Kommentar</span>
                <span className={css.separator}>|</span>
                <span>{name}</span>
            </p>
        </div>
    );
};
