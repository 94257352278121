import { clsx } from 'clsx';
import type {
    TeaserResponseType,
    TeaserVariantSubtitle,
    TeaserVariantTitle,
} from '@innhold/core/front/types';
import type { TeaserSizeType } from '../../types';
import { getFontWeight } from '../../utils/fonts';
import { mapImportanceToClassName } from '../../utils/importance';
import { hasSkinWithByline, mapSkinsToClassNames } from '../../utils/skins';
import { hasSubtypeWithIcon } from '../../utils/subtypes';
import { TeaserIcon } from '../TeaserIcon';
import { TeaserSection } from '../TeaserSection';
import { Title } from './variants';
import css from './TeaserTitle.module.css';

type TeaserTitleProps = {
    sourceId: string;
    kicker?: string;
    value: TeaserVariantTitle;
    section: TeaserVariantSubtitle;
    importance: TeaserResponseType['importance'];
    skins?: TeaserResponseType['skins'];
    subtype: string;
    teaserSize: TeaserSizeType;
};

const isTitleWithIcon = (
    kicker: string,
    skins: TeaserResponseType['skins'],
    subtype: string,
    teaserSize: TeaserSizeType
) => {
    if (hasSubtypeWithIcon(subtype)) {
        if (teaserSize === 'stripe') return !kicker;
        return !hasSkinWithByline(skins);
    }

    return false;
};

const isTitleWithSection = (
    section: TeaserVariantSubtitle,
    skins: TeaserResponseType['skins']
) => {
    return hasSkinWithByline(skins) ? false : Boolean(section?.label);
};

const isTitleWithSectionIcon = (subtype: string, teaserSize: TeaserSizeType) => {
    return hasSubtypeWithIcon(subtype) ? teaserSize === 'stripe' : false;
};

export const TeaserTitle = ({
    sourceId,
    kicker,
    value,
    section,
    importance,
    skins = [],
    subtype,
    teaserSize,
}: TeaserTitleProps) => {
    const fontSize = value.sizes.find((size) => size.containerSize === teaserSize);
    const fontWeight = getFontWeight(importance);

    const withIcon = isTitleWithIcon(kicker, skins, subtype, teaserSize);
    const withKicker = Boolean(kicker);
    const withSection = isTitleWithSection(section, skins);
    const withSectionIcon = withSection
        ? isTitleWithSectionIcon(subtype, teaserSize)
        : false;

    return (
        <div
            className={clsx(
                css.title,
                css[teaserSize],
                mapImportanceToClassName(importance, css),
                mapSkinsToClassNames(skins, css)
            )}
        >
            {withIcon && <TeaserIcon subtype={subtype} teaserSize={teaserSize} />}
            {withKicker && <p className={css.kicker}>{kicker}</p>}
            <Title
                sourceId={sourceId}
                text={value.text}
                fontSize={fontSize}
                fontWeight={fontWeight}
                importance={importance}
            />
            {withSection && (
                <TeaserSection
                    section={{
                        ...section,
                        icon: withSectionIcon
                            ? { subtype, role: withIcon ? 'supplementary' : 'primary' }
                            : null,
                    }}
                    teaserSize={teaserSize}
                />
            )}
        </div>
    );
};
