export const injectSafariOnlyStyles = (
    selector: string,
    property: string,
    value: string
) => {
    // code for selector taken from: https://browserstrangeness.bitbucket.io/css_hacks.html#safari
    return `
        _:-webkit-full-screen, _::-webkit-full-page-media, _:future, :root {
            ${selector} {
                ${property}: ${value};
            } 
        }`;
};
