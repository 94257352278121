import type {
    BaseLayoutType,
    BatchLayoutType,
    BlockLayoutType,
    BundleLayoutType,
} from '../types';

export const getBaseLayouts = (layout: BlockLayoutType): BaseLayoutType[] => {
    switch (layout) {
        case 'breaking-tower':
        case 'tower':
            return ['fullWidth', 'double'];
        case 'flipped-tower':
            return ['double', 'fullWidth'];
        case 'triple':
            return ['stripe', 'stripe', 'stripe'];
        default:
            return [layout];
    }
};

export const getBlockLayouts = (layout: BundleLayoutType): BlockLayoutType[] => {
    switch (layout) {
        case 'fourA':
            return ['stripe', 'breaking-tower'];
        case 'fourB':
            return ['stripe', 'tower'];
        case 'fourC':
            return ['stripe', 'double', 'stripe'];
        case 'fourD':
            return ['stripe', 'fullWidth', 'double'];
        case 'fourE':
            return ['fullWidth', 'stripe', 'double'];
        case 'fourF':
            return ['stripe', 'stripe', 'double'];
        case 'fullstack':
            return ['fullWidth', 'stripe', 'stripe'];
        case 'sandwich':
            return ['stripe', 'fullWidth', 'stripe'];
        case 'hippo':
            return ['fullWidth', 'double'];
        case 'robot':
            return ['double', 'stripe'];
        case 'flipped-robot':
            return ['stripe', 'double'];
        case 'breaking':
            return ['fullWidth', 'stripe'];
        case 'flipped-breaking':
            return ['stripe', 'fullWidth'];
        case 'stack':
            return ['stripe', 'stripe'];
        default:
            return [layout];
    }
};

export const getLayoutSize = (layout: BatchLayoutType) => {
    switch (layout) {
        case 'breaking-tower':
        case 'robot':
        case 'flipped-robot':
        case 'hippo':
        case 'sandwich':
        case 'fullstack':
        case 'tower':
        case 'flipped-tower':
        case 'triple':
            return 3;
        case 'breaking':
        case 'flipped-breaking':
        case 'stack':
        case 'double':
            return 2;
        case 'fullWidth':
        case 'stripe':
        default:
            return 1;
    }
};

export const isFlipLayout = (layout: BundleLayoutType) => {
    return layout === 'flipped-tower';
};

export const flipLayoutItems = <Item>(items: Item[], layout: BlockLayoutType) => {
    if (layout === 'flipped-tower') {
        const [mainItem, ...sideItems] = items;
        return [...sideItems, mainItem];
    }

    return items;
};
