import type { TeaserResponseType } from '@innhold/core/front/types';
import {
    DATA_ACCESS_LEVEL,
    DATA_ACCESS_LEVEL_NAME,
    DATA_BUNDLE,
    DATA_CONTENT_ID,
    DATA_ELEMENT_SIZE,
    DATA_EXPERIMENT_TEST_ID,
    DATA_EXPERIMENT_VARIANT_ID,
    DATA_IMAGE,
    DATA_METRICS,
    DATA_POSITION,
    DATA_SLUG,
    DATA_SOURCE,
    DATA_TITLE,
} from '@innhold/core/tracking/constants';
import type { TeaserSizeType } from '../types';
import { getDefaultImageUrl } from './images';
import { mapSizeToElementSize } from './sizes';

export const getTeaserDataAttributes = (
    teaser: TeaserResponseType,
    size: TeaserSizeType
) => {
    const {
        sourceId,
        image,
        title,
        url,
        meta,
        accessLevel,
        accessLevelName,
        experiment = null,
    } = teaser;

    const teaserDataAttributes = {
        [DATA_CONTENT_ID]: sourceId,
        [DATA_TITLE]: title.text.join(' '),
        [DATA_ELEMENT_SIZE]: mapSizeToElementSize(size),
        [DATA_ACCESS_LEVEL]: accessLevel,
        [DATA_ACCESS_LEVEL_NAME]: accessLevelName,
        [DATA_SLUG]: url,
    };

    if (image) {
        teaserDataAttributes[DATA_IMAGE] = getDefaultImageUrl(image.sizes);
    }

    if (meta) {
        const isPartOfBundle = Boolean(meta.bundle);
        if (isPartOfBundle) {
            teaserDataAttributes[DATA_BUNDLE] = meta.bundle.join(',');
        }

        teaserDataAttributes[DATA_POSITION] = meta.position;

        const { r = 0, i = 0, p = 0 } = meta.metrics ?? {};
        teaserDataAttributes[DATA_METRICS] = [r, i, p].join(',');

        teaserDataAttributes[DATA_SOURCE] = meta.src;
    }

    if (experiment) {
        teaserDataAttributes[DATA_EXPERIMENT_VARIANT_ID] = experiment.variant;
        teaserDataAttributes[DATA_EXPERIMENT_TEST_ID] = experiment.custom.testId;
    }

    return teaserDataAttributes;
};
